import React, { useState } from 'react'
import { Button, Modal } from 'antd'

function ResetModal(props: any) {
    return (
        <Modal
            // title="すべてのデータが削除されます。"
            visible={props.resetModal}
            onCancel={props.hideModal}
            centered
            title={[

            ]}
            footer={[
                <Button onClick={props.resetData} className='ant-btn-primary'>OK</Button>
            ]}
            className="reset-modal"
        >
            <h4>すべてのデータが削除されます。</h4>
            <p>リセットしますか？</p>
        </Modal>
    )
}

export default ResetModal