import React, { useState, useEffect, useRef } from "react";
import "antd/dist/antd.css";
import { Row, Col, Button, Input, Alert, Select, Tooltip } from "antd";
import "../assets/scss/main.scss";
import { fabric } from "fabric";
import moment from "moment";
import selectionTool from '../assets/images/selection_tool.png';
import axiosInstance from "../axios/axios";
import incognitoDetect from "../api/detectIncognito";
import { callbackify } from "util";
import ResetModal from "./ResetModal";
import { generateString } from "../utils/generateString";

// let counter = 0;
let obj: any = [];
let duplicateObj: any = [];
let canvas: any;
let newInstrumentList: any = [];
let canvasDataFromId: any = [];

const { Option } = Select;

function MainLayout() {
  const [counter, setCounter] = useState<number>(0);
  const [width, setWidth] = useState("0");
  const [height, setHeight] = useState("0");
  const [offsetTop, setOffsetTop] = useState("0");
  const [offsetLeft, setOffsetLeft] = useState("0");
  const [identifier, setIdentifier] = useState("");
  const [order, setOrder] = useState<string>();
  const [instrumentId, setInstrumentId] = useState("");
  const [label, setLabel] = useState("");
  const [instrumentTitle, setInstrumentTitle] = useState("");
  const [objectListData, setObjectList] = useState([]);
  const [showBox, setShowBox] = useState<boolean>(false);
  const [labelEnable, setLabelEnable] = useState<boolean>(false);
  const [deleteEnable, setDeleteEnable] = useState<boolean>(false);
  const [showErrorMessage, setErrorMessage] = useState<boolean>(false);
  const [showImageErrorMessage, setImageErrorMessage] = useState<boolean>(false);
  const [largeImageErrorMessage, setlargeImageMessage] = useState<boolean>(false);
  const [imageResError, setImageResError] = useState<boolean>(false);
  const [saveSuccessAlert, setSaveSuccessAlert] = useState(false);
  const [deleteSuccessAlert, setDeleteSuccessAlert] = useState(false);
  const [resetSuccessAlert, setResetSuccessAlert] = useState(false);
  const [importAlert, setImportAlert] = useState(false);
  const [exportAlert, setExportAlert] = useState(false);
  const [duplicateInstrumentModal, setDuplicateInstrumentModal] = useState(false);
  const [rectErrorModal, setRectErrorModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [rectTooltip, setRectTooltip] = useState<boolean>();
  const [rectTooltipMessage, setRectTooltipMessage] = useState<string>();
  const [baseImage, setBaseImage] = useState<string>();
  const [filteredOptions, setFilteredOptions] = useState<any>();
  const [totalInstrument, setTotalInstrument] = useState<string>();
  const [initialText, setInitialText] = useState<boolean>(true);
  const [resetModal, setResetModal] = useState(false);
  const [apiErrorAlert, setApiErrorAlert] = useState(false)
  const [apiErrorMessage, setApiErrorMessage] = useState('')
  const isSessionExists = window.localStorage.getItem("session_id")

  const showResetModal = () => {
    setResetModal(true);
  };

  const closeResetModal = () => {
    setResetModal(false);
  };


  let imageLoader = useRef<any>(null);

  // extract token and id from url
  let search = window.location.search.substring(1);
  let params = JSON.parse('{"' + decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}')
  // set orchestra id
  const orchestraId = params ?.id

  const randomString = generateString(20);

  useEffect(() => {
    if (isSessionExists === null || isSessionExists === '') {
      localStorage.setItem("session_id", randomString)
    }

    localStorage.removeItem("accessToken")
    localStorage.removeItem("canvasSaveData");
    localStorage.removeItem("canvasLocalData");
    localStorage.removeItem("canvasImage");
    localStorage.removeItem("base_image");
    localStorage.removeItem("importedData");
    localStorage.removeItem("counterValue");
    localStorage.removeItem("baseIdentifier");
    incognitoDetect();

    const getInstrumentNumbers: any = localStorage.getItem("instrumentDetail")
    const instrumentNumbers = JSON.parse(getInstrumentNumbers);
    setTotalInstrument(instrumentNumbers ?.length)
    canvas = new fabric.Canvas("canvas", {
      height: 300,
      width: 500,
      backgroundColor: "white",
      selection: true,
      selectionBorderColor: "green",
      preserveObjectStacking: true,
      enableRetinaScaling: false,
      imageSmoothingEnabled: false,
    });
    fetchOrchestraIdDetail();
    fetchOrchestraInfo();
    setShowBox(false);
    setErrorMessage(false);
    let baseIdentifier = uuid();
    localStorage.setItem("baseIdentifier", JSON.stringify(baseIdentifier));
    showObjectList();
    let getCanvasData: any = localStorage.getItem("canvasLocalData");
    let getCanvasImageData: any = localStorage.getItem("canvasImage");
    let getImportedData: any = localStorage.getItem("importedData");
    let canvasImportData = JSON.parse(getImportedData);
    if (getCanvasData !== null) {
      addNewRect(canvas);
    }
    if (getImportedData !== null) {
      addNewRectfromJSON(canvas);
      addImageFromStorage(canvasImportData.base_image);
      showImportObjectList();
      localStorage.removeItem("canvasLocalData");
      localStorage.removeItem("canvasImage");
      localStorage.removeItem("counterValue");
      localStorage.setItem(
        "counterValue",
        JSON.stringify(canvasImportData ?.objects ?.length)
      );
      setCounter(canvasImportData ?.objects ?.length);
    }

    if (getCanvasImageData) {
      addImageFromStorage(getCanvasImageData);
    }

    let counterData: any = localStorage.getItem("counterValue");
    if (counterData) {
      setCounter(parseInt(counterData));
    }

    let boundary: any = new fabric.Rect({
      width: 490, height: 250,
      left: 5, top: 5,
      selectable: false,
      strokeDashArray: [5, 2],
      stroke: "transparent",
      fill: "transparent"
    });
    const inside = (p: any, vs: any) => {
      let inside = false;
      for (let i = 0, j = vs.length - 1; i < vs.length; j = i++) {
        let xi = vs[i].x, yi = vs[i].y;
        let xj = vs[j].x, yj = vs[j].y;
        let intersect = yi > p.y !== yj > p.y && p.x < ((xj - xi) * (p.y - yi)) / (yj - yi) + xi;
        if (intersect) inside = !inside;
      }
      return inside;
    }
    const getCoords = (rect: any) => {
      let coords = []
      coords.push(rect.aCoords.tl);
      coords.push(rect.aCoords.tr);
      coords.push(rect.aCoords.br);
      coords.push(rect.aCoords.bl);
      coords.push(rect.aCoords.tl);
      return coords;
    }
    const objectMoving = (e: any) => {
      let cCoords = getCoords(parent);
      let inBounds = inside({ x: e.target.left + 30, y: e.target.top + 30 }, cCoords);

      if (inBounds) {
        e.target.setCoords();
        e.target.saveState();
      } else {
        e.target.left = e.target._stateProperties.left;
        e.target.top = e.target._stateProperties.top;
      }
    }
    let parent = new fabric.Rect({
      width: 460, height: 280,
      left: 30, top: 30,
      selectable: false,
      strokeDashArray: [2, 5],
      stroke: "transparent",
      fill: "transparent"
    });

    canvas.add(boundary);
    canvas.add(parent);
    canvas.on("object:moving", objectMoving);
  }, []);


  // Function for setting minimum width of the rect
  const limiWidth = (rectNew: any) => {
    var minSize = 50;
    rectNew.minScaleLimit = minSize / rectNew.getScaledWidth();
  }

  const fetchOrchestraIdDetail = async () => {
    try {
      let response = await axiosInstance.get(`/orchestra-instrument/${orchestraId}`);
      if (response) {
        newInstrumentList = response ?.data ?.data;
        // Save orchestra data in local storage 
        localStorage.setItem("instrumentDetail", JSON.stringify(newInstrumentList));
        const intrumentTitle = newInstrumentList.map((instrument: any) => {
          return instrument ?.title
        })
        setFilteredOptions([...intrumentTitle]);
      }
    } catch (error) {
      console.log(error);

    }
  }


  const fetchOrchestraInfo = async () => {
    try {
      let response = await axiosInstance.get(`/orchestra-layout/${orchestraId}`);
      if (response) {

        // Set fertched data from Id to canvasLocalData      
        canvasDataFromId = response.data.data;
        let baseImage = canvasDataFromId.base_image;
        let objectList = canvasDataFromId.layouts;

        addImageFromId(baseImage);
        setBaseImage(baseImage)
        // set the objects in storage

        if (objectList ?.length > 0) {
          for (let i = 0; i < objectList ?.length; i++) {
            const rectNew: any = new fabric.Rect({
              height: objectList[i].h,
              width: objectList[i].w,
              fill: "rgba(255,0,0,0)",
              stroke: "black",
              strokeWidth: 1,
              top: parseInt(objectList[i].y),
              left: parseInt(objectList[i].x),
            });
            // limiWidth(rectNew)

            //Order
            let attribute1 = "order";
            let value1 = objectList[i].order;
            rectNew[attribute1] = value1;
            let attribute2 = "identifier";
            let value2 = objectList[i].identifier;
            rectNew[attribute2] = value2;
            canvas.add(rectNew);

            canvas.renderAll();

            const objectsData = {
              identifier: objectList[i].identifier,
              label: objectList[i].label,
              w: objectList[i].w,
              h: objectList[i].h,
              x: objectList[i].x,
              y: objectList[i].y,
              instrument_id: objectList[i].instrument_id,
              instrument_title: objectList[i].instrument_title,
              instrument_musician: objectList[i].instrument_musician,
              musician_image: objectList[i].musician_image,
              musician_id: objectList[i].musician_id,
              description: objectList[i].description,
              created_at: objectList[i].created_at,
              recently_edited_at: objectList[i].recently_edited_at,
            };
            obj.push(objectsData);
            let counterIncr = counter + 1;
            setCounter(counterIncr)
          }
        }

        let newInstrumentData = objectList.map(function (e: any) {
          return e.instrument_title;
        });

        setObjectList(newInstrumentData);

        //Mouse up
        canvas.on("mouse:up", function (e: any) {
          if (e.currentTarget) {

            let currentHeight = e.currentTarget.height * e.currentTarget.scaleY;
            let currentWidth = e.currentTarget.width * e.currentTarget.scaleX;
            let currentLeft = e.currentTarget.left;
            currentLeft = currentLeft.toFixed(2);
            let currentTop = e.currentTarget.top;
            currentTop = currentTop.toFixed(2);
            changeWidth(currentWidth.toFixed(2));
            changeHeight(currentHeight.toFixed(2));
            changeOffsetLeft(currentLeft);
            changeOffsetTop(currentTop);
            let identifier = e.currentTarget.identifier;
            let order = e.currentTarget.order;
            changeIdentifier(identifier);
            changeOrder(order);

            // Update localStorage Value
            let getCanvasData: any = localStorage.getItem("canvasLocalData");
            let getCanvasSaveData: any = localStorage.getItem("canvasSaveData");
            //Retrieve the object
            let canvasData = JSON.parse(getCanvasData);
            let canvasSaveData = JSON.parse(getCanvasSaveData);

            let updateCanvas = canvasData.objects.find(function (e: any) {
              return e.identifier === identifier;
            });

            let updateSaveCanvas = canvasSaveData.objects.find(function (e: any) {
              return e.identifier === identifier;
            });

            if (updateSaveCanvas) {
              (document.getElementById("test") as HTMLInputElement).value =
                updateSaveCanvas.instrument_title;
              updateSaveCanvas.w = currentWidth;
              updateSaveCanvas.h = currentHeight;
              updateSaveCanvas.x = currentLeft;
              updateSaveCanvas.y = currentTop;
              updateSaveCanvas.recently_edited_at = moment().format("l, h:mm:ss a");
              if (e.currentTarget.identifier === updateSaveCanvas.identifier) {
                setLabel(updateSaveCanvas.label);
                setInstrumentTitle(updateSaveCanvas.instrument_title);
                setInstrumentId(updateSaveCanvas.instrument_id);
                setLabelEnable(true)
                setDeleteEnable(true)
              }
            }

            if (updateCanvas) {
              (document.getElementById("test") as HTMLInputElement).value =
                updateCanvas.instrument_title;
              updateCanvas.w = currentWidth;
              updateCanvas.h = currentHeight;
              updateCanvas.x = currentLeft;
              updateCanvas.y = currentTop;
              updateCanvas.recently_edited_at = moment().format("l, h:mm:ss a");
              if (e.currentTarget.identifier === updateCanvas.identifier) {
                setLabel(updateCanvas.label);
                setInstrumentTitle(updateCanvas.instrument_title);
                setInstrumentId(updateCanvas.instrument_id);
                setLabelEnable(true)
                setDeleteEnable(true)
              }
            }
            localStorage.setItem("canvasLocalData", JSON.stringify(canvasData));
            localStorage.setItem("canvasSaveData", JSON.stringify(canvasSaveData));
          }
        });
        // Mouse up

        if (canvasDataFromId) {
          const canvasData = {
            base_image: baseImage,
            objects: obj,
            identifier: baseIdentifierValue,
            title: "Nakuto Band Orchestra Layout",
            orchestra_id: orchestraId,
          };
          const canvasSaveData = {
            objects: obj,
            orchestra_id: orchestraId,
          };
          localStorage.setItem("canvasLocalData", JSON.stringify(canvasData));
          localStorage.setItem("canvasSaveData", JSON.stringify(canvasSaveData));
          localStorage.setItem("counterValue", JSON.stringify(counter));

        }
        // const filterLayoutsTitle = objectList?.map((mapItems: any) => {
        //   return mapItems?.instrument_title
        // });
        // const filterItemsTitle = filteredOptions?.map((mapItems: any) => {
        //   console.log("mapItems", mapItems)
        //   return mapItems
        // });


        // const itemFilteredValued = filterItemsTitle?.filter(
        //   (e: any) => !filterLayoutsTitle.includes(e)
        // );

        // setFilteredOptions(itemFilteredValued)

        // console.log("itemFilteredValued", itemFilteredValued);

      }

    } catch (error: any) {
    }
  }


  //Import  JSON File
  const importFile = (e: any) => {
    canvas.clear();
    let fileInput = document.getElementById("file") as HTMLInputElement;
    let filePath = fileInput.value;
    let allowedExtensions = /(\.json)$/i;
    if (!allowedExtensions.exec(filePath)) {
      fileInput.value = "";
      setErrorMessage(true);
      return false;
    } else {
      if (fileInput.files && fileInput.files[0]) {
        let reader = new FileReader();
        reader.onload = function (e: any) {
          let jsonObj = JSON.parse(e.target.result);

          localStorage.setItem("importedData", JSON.stringify(jsonObj));
          localStorage.setItem("counterValue", JSON.stringify(jsonObj.objects ?.length));
          if (jsonObj) {
            addImageFromStorage(baseImage);
            addNewRectfromJSON(canvas);

          }
        };

        reader.readAsText(e.target.files[0]);
        setErrorMessage(false);
      }

    }
  };
  // Import JSON File

  //Draw rect if local storage has value
  const addNewRect = (canvas: any) => {
    let getCanvasData: any = localStorage.getItem("canvasLocalData");
    let canvasData = JSON.parse(getCanvasData);
    // setOrchestraId(canvasData.orchestra_id);
    for (let i = 0; i < canvasData.objects.length; i++) {
      const rectNew: any = new fabric.Rect({
        height: canvasData.objects[i].h,
        width: canvasData.objects[i].w,
        fill: "rgba(255,0,0,0)",
        stroke: "black",
        strokeWidth: 1,
        top: parseInt(canvasData.objects[i].y),
        left: parseInt(canvasData.objects[i].x),
      });
      // Identifier value
      let attribute1 = "identifier";
      let value1 = canvasData.objects[i].identifier;

      //  Label
      let attribute2 = "label";
      let value2 = canvasData.objects[i].label;

      //Order
      let attribute3 = "order";
      let value3 = canvasData.objects[i].order;
      rectNew[attribute1] = value1;
      rectNew[attribute2] = value2;
      rectNew[attribute3] = value3;
      canvas.add(rectNew);
      let minSize = 50;
      rectNew.minScaleLimit = minSize / rectNew.getScaledWidth();
      canvas.renderAll();


      //Mouse up
      canvas.on("mouse:up", function (e: any) {
        if (e.currentTarget) {
          let currentHeight = e.currentTarget.height * e.currentTarget.scaleY;
          let currentWidth = e.currentTarget.width * e.currentTarget.scaleX;
          let currentLeft = e.currentTarget.left;
          currentLeft = currentLeft.toFixed(2);
          let currentTop = e.currentTarget.top;
          currentTop = currentTop.toFixed(2);
          changeWidth(currentWidth.toFixed(2));
          changeHeight(currentHeight.toFixed(2));
          changeOffsetLeft(currentLeft);
          changeOffsetTop(currentTop);
          let identifier = e.currentTarget.identifier;
          let order = e.currentTarget.order;
          changeIdentifier(identifier);
          changeOrder(order);

          // Update localStorage Value

          let getCanvasData: any = localStorage.getItem("canvasLocalData");
          //Retrieve the object
          let canvasData = JSON.parse(getCanvasData);
          let updateCanvas = canvasData.objects.find(function (e: any) {
            return e.identifier === identifier;
          });
          if (updateCanvas) {
            (document.getElementById("test") as HTMLInputElement).value =
              updateCanvas.instrument_title;
            updateCanvas.w = currentWidth;
            updateCanvas.h = currentHeight;
            updateCanvas.x = currentLeft;
            updateCanvas.y = currentTop;
            updateCanvas.recently_edited_at = moment().format("l, h:mm:ss a");
            if (e.currentTarget.identifier === updateCanvas.identifier) {
              setLabel(updateCanvas.label);
              setInstrumentTitle(updateCanvas.instrument_title);
              setInstrumentId(updateCanvas.instrument_id);
              setLabelEnable(true)
              setDeleteEnable(true)
            }
          }
          localStorage.setItem("canvasLocalData", JSON.stringify(canvasData));
        }
      });
      // Mouse up
    }
  };
  // Draw rect if local storage has value

  // Draw rect from jsonObject
  const addNewRectfromJSON = (canvas: any) => {
    let getImportedData: any = localStorage.getItem("importedData");
    let canvasData = JSON.parse(getImportedData);
    for (let i = 0; i < canvasData.objects.length; i++) {
      const rectJSON: any = new fabric.Rect({
        height: canvasData.objects[i].h,
        width: canvasData.objects[i].w,
        fill: "rgba(255,0,0,0)",
        stroke: "black",
        strokeWidth: 1,
        top: parseInt(canvasData.objects[i].y),
        left: parseInt(canvasData.objects[i].x),
      });
      // Identifier value
      let attribute1 = "identifier";
      let value1 = canvasData.objects[i].identifier;

      //  Label
      let attribute2 = "label";
      let value2 = canvasData.objects[i].label;

      //Order
      let attribute3 = "order";
      let value3 = canvasData.objects[i].order;
      rectJSON[attribute1] = value1;
      rectJSON[attribute2] = value2;
      rectJSON[attribute3] = value3;
      canvas.add(rectJSON);
      canvas.renderAll();
      showImportObjectList();

      //Mouse up
      canvas.on("mouse:up", function (e: any) {
        if (e.currentTarget) {
          let currentHeight = e.currentTarget.height * e.currentTarget.scaleY;
          let currentWidth = e.currentTarget.width * e.currentTarget.scaleX;
          let currentLeft = e.currentTarget.left;
          currentLeft = currentLeft.toFixed(2);
          let currentTop = e.currentTarget.top;
          currentTop = currentTop.toFixed(2);
          changeWidth(currentWidth.toFixed(2));
          changeHeight(currentHeight.toFixed(2));
          changeOffsetLeft(currentLeft);
          changeOffsetTop(currentTop);
          let identifier = e.currentTarget.identifier;
          let order = e.currentTarget.order;
          changeIdentifier(identifier);
          changeOrder(order);

          // Update localStorage Value
          let getImportedData: any = localStorage.getItem("importedData");
          let canvasData = JSON.parse(getImportedData);

          let updateCanvas = canvasData.objects.find(function (e: any) {
            return e.identifier === identifier;
          });
          if (updateCanvas) {
            (document.getElementById("test") as HTMLInputElement).value =
              updateCanvas.instrument_title;
            updateCanvas.w = currentWidth;
            updateCanvas.h = currentHeight;
            updateCanvas.x = currentLeft;
            updateCanvas.y = currentTop;
            updateCanvas.recently_edited_at = moment().format("l, h:mm:ss a");
            if (e.currentTarget.identifier === updateCanvas.identifier) {
              setLabel(updateCanvas.label);
              setInstrumentTitle(updateCanvas.instrument_title);
              setInstrumentId(updateCanvas.instrument_id);
              setLabelEnable(true)
              setDeleteEnable(true)
            }
          }
          localStorage.setItem("importedData", JSON.stringify(canvasData));
        }
      });
      // Mouse up
    }
  };
  // Draw rect from jsonObject

  // draw new rectangle when clicked
  function uuid() {
    return "xxxx4xxxyxxx".replace(/[xy]/g, function (c) {
      let r = (Math.random() * 16) | 0,
        v = c === "x" ? r : r && 0x3 | 0x8;
      return v.toString(16);
    });
  }

  let randomNumber = uuid();
  let identifierValue = randomNumber.toString();
  let baseIdentifierValue: any = localStorage.getItem("baseIdentifier");

  const addRect = (canvasvalues: any) => {
    const getInstrumentDetail: any = localStorage.getItem("instrumentDetail");
    const instrumentDetail = JSON.parse(getInstrumentDetail)

    if (objectListData.length < instrumentDetail.length && showBox) {
      const rect: any = new fabric.Rect({
        height: 120,
        width: 100,
        fill: "rgba(255,0,0,0)",
        stroke: "black",
        strokeWidth: 1,
      });
      setWidth("0");
      setHeight("0");
      setOffsetTop("0");
      setOffsetLeft("0");
      let attribute1 = "identifier";
      let value1 = identifierValue;

      let attribute2 = "order";
      let counterIncr = counter + 1;
      setCounter(counterIncr)
      let value2 = counterIncr;
      localStorage.setItem("counterValue", JSON.stringify(value2));
      rect[attribute1] = value1;
      rect[attribute2] = value2;
      canvasvalues.add(rect);
      canvasvalues.renderAll();
      let getCanvasData: any = localStorage.getItem("canvasLocalData");
      let getCanvasSaveData: any = localStorage.getItem("canvasSaveData");
      let getCanvasImage: any = localStorage.getItem("canvasImage");

      // if imported Data
      let getImportedData: any = localStorage.getItem("importedData");
      if (getImportedData !== null) {
        const objectsData = {
          identifier: identifierValue,
          label: "",
          w: 0,
          h: 0,
          x: 0,
          y: 0,
          instrument_id: "",
          instrument_title: "",
          instrument_musician: "",
          musician_image: "",
          musician_id: "",
          description: "",
          order: value2,
          created_at: moment().format("l, h:mm:ss a"),
          recently_edited_at: moment().format("l, h:mm:ss a"),
        };
        obj.push(objectsData);

        if (getImportedData !== null) {
          let canvasImportData = JSON.parse(getImportedData);
          canvasImportData.objects.push(objectsData);
          localStorage.setItem(
            "importedData",
            JSON.stringify(canvasImportData)
          );
        } else {
          const canvasImportData = {
            identifier: baseIdentifierValue,
            title: "Nakuto Band Orchestra Layout",
            base_image: getCanvasImage,
            orchestra_id: orchestraId,
            objects: obj,
          };
          localStorage.setItem(
            "importedData",
            JSON.stringify(canvasImportData)
          );
        }
        // when certain changes is done in selection tool
        showImportObjectList();
        canvasvalues.on("object:modified", function (e: any) {
          let activeObject = e.target;
          if (!activeObject) {
            return;
          }

          let newWidth = activeObject.width * activeObject.scaleX;
          let newHeight = activeObject.height * activeObject.scaleY;
          let activeOffsetTop = activeObject.top;
          activeOffsetTop = activeOffsetTop.toFixed(2);
          let activeOffsetLeft = activeObject.left;
          activeOffsetLeft = activeOffsetLeft.toFixed(2);
          let identifier = activeObject.identifier;
          changeWidth(newWidth.toFixed(2));
          changeHeight(newHeight.toFixed(2));
          changeOffsetLeft(activeOffsetLeft);
          changeOffsetTop(activeOffsetTop);
          // Update localStorage Value

          // let getCanvasData: any = localStorage.getItem("canvasLocalData");
          //Retrieve the object
          let canvasImportData = JSON.parse(getImportedData);
          let updateCanvas = canvasImportData.objects.find(function (e: any) {
            return e.identifier === identifier;
          });
          if (updateCanvas) {
            updateCanvas.w = newWidth;
            updateCanvas.h = newHeight;
            updateCanvas.x = activeOffsetLeft;
            updateCanvas.y = activeOffsetTop;
            updateCanvas.recently_edited_at = moment().format("l, h:mm:ss a");
          }
          localStorage.setItem("importedData", JSON.stringify(canvasImportData));
        });

        // On mouse move

        canvas.on("mouse:up", function (e: any) {
          if (e.currentTarget) {
            let currentHeight = e.currentTarget.height * e.currentTarget.scaleY;
            let currentWidth = e.currentTarget.width * e.currentTarget.scaleX;
            let currentLeft = e.currentTarget.left;
            currentLeft = currentLeft.toFixed(2);
            let currentTop = e.currentTarget.top;
            currentTop = currentTop.toFixed(2);
            changeWidth(currentWidth.toFixed(2));
            changeHeight(currentHeight.toFixed(2));
            changeOffsetLeft(currentLeft);
            changeOffsetTop(currentTop);
            let identifier = e.currentTarget.identifier;
            let order = e.currentTarget.order;
            changeIdentifier(identifier);
            changeOrder(order);

            // Update localStorage Value

            let canvasImportData = JSON.parse(getImportedData);

            let updateCanvas = canvasImportData.objects.find(function (e: any) {
              return e.identifier === identifier;
            });
            if (updateCanvas) {
              (document.getElementById("test") as HTMLInputElement).value =
                updateCanvas.instrument_title;
              updateCanvas.w = currentWidth;
              updateCanvas.h = currentHeight;
              updateCanvas.x = currentLeft;
              updateCanvas.y = currentTop;
              updateCanvas.recently_edited_at = moment().format("l, h:mm:ss a");
              if (e.currentTarget.identifier === updateCanvas.identifier) {
                setLabel(updateCanvas.label);
                setInstrumentTitle(updateCanvas.instrument_title);
                setInstrumentId(updateCanvas.instrument_id);
                setLabelEnable(true)
                setDeleteEnable(true)
              }
            }
            localStorage.setItem("importedData", JSON.stringify(canvasImportData));
          }
        });
      } else {
        const objectsData = {
          identifier: identifierValue,
          label: "",
          w: 100,
          h: 120,
          x: "0",
          y: "0",
          instrument_id: "",
          instrument_title: "",
          instrument_musician: "",
          musician_image: "",
          musician_id: "",
          description: "",
          order: value2,
          created_at: moment().format("l, h:mm:ss a"),
          recently_edited_at: moment().format("l, h:mm:ss a"),
        };
        obj.push(objectsData);

        if (getCanvasData !== null) {
          let canvasData = JSON.parse(getCanvasData);
          let canvasSaveData = JSON.parse(getCanvasSaveData);

          canvasData.objects.push(objectsData);
          canvasSaveData.objects.push(objectsData);
          localStorage.setItem("canvasLocalData", JSON.stringify(canvasData));
          localStorage.setItem("canvasSaveData", JSON.stringify(canvasSaveData));
        } else {
          const canvasData = {
            identifier: baseIdentifierValue,
            title: "Nakuto Band Orchestra Layout",
            base_image: getCanvasImage,
            orchestra_id: orchestraId,
            objects: obj,
          };
          const saveData = {
            orchestra_id: orchestraId,
            objects: obj,
          };

          localStorage.setItem("canvasLocalData", JSON.stringify(canvasData));
          localStorage.setItem("canvasSaveData", JSON.stringify(saveData));
          // when certain changes is done in selection tool
          showObjectList();
          canvasvalues.on("object:modified", function (e: any) {
            let activeObject = e.target;
            if (!activeObject) {
              return;
            }

            let newWidth = activeObject.width * activeObject.scaleX;
            let newHeight = activeObject.height * activeObject.scaleY;
            let activeOffsetTop = activeObject.top;
            activeOffsetTop = activeOffsetTop.toFixed(2);
            let activeOffsetLeft = activeObject.left;
            activeOffsetLeft = activeOffsetLeft.toFixed(2);
            let identifier = activeObject.identifier;
            changeWidth(newWidth.toFixed(2));
            changeHeight(newHeight.toFixed(2));
            changeOffsetLeft(activeOffsetLeft);
            changeOffsetTop(activeOffsetTop);
            // Update localStorage Value

            let getCanvasData: any = localStorage.getItem("canvasLocalData");
            //Retrieve the object
            let canvasData = JSON.parse(getCanvasData);
            let updateCanvas = canvasData.objects.find(function (e: any) {
              return e.identifier === identifier;
            });
            if (updateCanvas) {
              updateCanvas.w = newWidth;
              updateCanvas.h = newHeight;
              updateCanvas.x = activeOffsetLeft;
              updateCanvas.y = activeOffsetTop;
              updateCanvas.recently_edited_at = moment().format("l, h:mm:ss a");
            }
            localStorage.setItem("canvasLocalData", JSON.stringify(canvasData));

            //update height width from canvasSaveData
            let getCanvasSaveData: any = localStorage.getItem("canvasSaveData");
            let canvasSaveData = JSON.parse(getCanvasSaveData);
            let updateSaveCanvas = canvasSaveData.objects.find(function (e: any) {
              return e.identifier === identifier;
            });
            if (updateSaveCanvas) {
              updateSaveCanvas.w = newWidth;
              updateSaveCanvas.h = newHeight;
              updateSaveCanvas.x = activeOffsetLeft;
              updateSaveCanvas.y = activeOffsetTop;
              updateSaveCanvas.recently_edited_at = moment().format("l, h:mm:ss a");
            }
            localStorage.setItem("canvasSaveData", JSON.stringify(canvasSaveData));
          });

          // On mouse move

          canvas.on("mouse:up", function (e: any) {
            if (e.currentTarget) {
              let currentHeight = e.currentTarget.height * e.currentTarget.scaleY;
              let currentWidth = e.currentTarget.width * e.currentTarget.scaleX;
              let currentLeft = e.currentTarget.left;
              currentLeft = currentLeft.toFixed(2);
              let currentTop = e.currentTarget.top;
              currentTop = currentTop.toFixed(2);
              changeWidth(currentWidth.toFixed(2));
              changeHeight(currentHeight.toFixed(2));
              changeOffsetLeft(currentLeft);
              changeOffsetTop(currentTop);
              let identifier = e.currentTarget.identifier;
              let order = e.currentTarget.order;
              changeIdentifier(identifier);
              changeOrder(order);

              // Update localStorage Value

              let getCanvasData: any = localStorage.getItem("canvasLocalData");
              //Retrieve the object
              let canvasData = JSON.parse(getCanvasData);
              let updateCanvas = canvasData.objects.find(function (e: any) {
                return e.identifier === identifier;
              });
              if (updateCanvas) {
                (document.getElementById("test") as HTMLInputElement).value =
                  updateCanvas.instrument_title;
                updateCanvas.w = currentWidth;
                updateCanvas.h = currentHeight;
                updateCanvas.x = currentLeft;
                updateCanvas.y = currentTop;
                updateCanvas.recently_edited_at = moment().format("l, h:mm:ss a");
                if (e.currentTarget.identifier === updateCanvas.identifier) {
                  setLabel(updateCanvas.label);
                  setInstrumentTitle(updateCanvas.instrument_title);
                  setInstrumentId(updateCanvas.instrument_id);
                  setLabelEnable(true)
                  setDeleteEnable(true)
                }
              }
              localStorage.setItem("canvasLocalData", JSON.stringify(canvasData));
            }
          });
        }
      }
      setShowBox(false);
      setRectTooltip(true);
      setRectTooltipMessage("前の楽器の四角形を選択してください");
    } else {
      setRectTooltip(true)
      setRectTooltipMessage("無効な楽器です")
    }
  };

  const showObjectList = () => {
    let getCanvasData: any = localStorage.getItem("canvasLocalData");
    let canvasData = JSON.parse(getCanvasData);
    if (getCanvasData !== null) {
      let newInstrumentData = canvasData.objects.map(function (e: any) {
        return e.instrument_title;
      });
      setObjectList(newInstrumentData);
    }
  };

  const showImportObjectList = () => {
    let getCanvasData: any = localStorage.getItem("importedData");
    let canvasData = JSON.parse(getCanvasData);
    if (getCanvasData !== null) {
      let newInstrumentData = canvasData.objects.map(function (e: any) {
        return e.instrument_title;
      });
      setObjectList(newInstrumentData);
    }
  };

  // Upload image

  const onhandleImage = async (e: any) => {
    const checkValid = await incognitoDetect()
    setImageResError(false);
    setlargeImageMessage(false);
    setImageErrorMessage(false);

    // 16:9 ratio validation
    const imgRatio = parseFloat("1.777777777777778").toFixed(5);

    if (checkValid) {
      setlargeImageMessage(false)
      canvas.setBackgroundColor("", canvas.renderAll.bind(canvas));
      canvas.setBackgroundImage(0, canvas.renderAll.bind(canvas));

      //Test
      let fileInput: any = document.getElementById("uploader") as HTMLInputElement;
      let filePath = fileInput.value;
      let allowedExtensions = /(\.(jpe?g|png))$/i;

      const fileSize: number = e.target.files.item(0).size;
      const fileMb: number = fileSize / 1024 ** 2;

      if (fileMb < 2) {
        if (!allowedExtensions.exec(filePath)) {
          fileInput.value = "";
          setImageErrorMessage(true);
          return false;
        } else {
          if (fileInput.files && fileInput.files[0]) {
            let url = URL.createObjectURL(fileInput.files[0]);
            let reader = new FileReader();
            reader.onload = function (file: any) {
              const img = new Image();
              img.src = url;
              img.onload = async function () {
                URL.revokeObjectURL(img.src);
                let currentRatio = img.width / img.height;
                let finalRatio = parseFloat(currentRatio.toString()).toFixed(5)

                if (finalRatio == imgRatio) {

                  addImage(file.target.result);
                  localStorage.setItem("canvasImage", file.target.result);
                  localStorage.setItem("canvasDetail", canvas);
                  const uploadData = new FormData();
                  uploadData.append("image", e.target.files[0]);
                  uploadData.append("orchestra_id", orchestraId);
                  // Call Image Upload api
                  // let uploadPayload = {
                  //   orchestra_id: orchestraId,
                  //   image: file.target.result,
                  // };

                  const config = {
                    headers: { 'Content-Type': 'multipart/form-data' }
                  }

                  try {
                    let response = await axiosInstance.post('/orchestra-layout-image', uploadData, config);
                    if (response.status === 200) {
                      let base_image = response ?.data ?.data ?.image
                      setBaseImage(base_image);
                      setInitialText(false)
                    }
                  } catch (error) {
                    console.log(error);
                  }
                } else {
                  fileInput.value = "";
                  setImageResError(true);
                }

              }
            };
            reader.readAsDataURL(e.target.files[0]);
            setImageErrorMessage(false);
          }
        }
      } else {
        fileInput.value = "";
        setlargeImageMessage(true);
      }
    }

  };

  function addImage(imgLink: any) {
    fabric.Image.fromURL(imgLink, function (img: any) {
      if (img) {
        canvas.setBackgroundImage(img, canvas.renderAll.bind(canvas), {
          scaleX: canvas.width / img.width,
          scaleY: canvas.height / img.height,
        });
        setInitialText(false)
      }
    });

    setShowBox(true);
  }

  function addImageFromId(imgLink: any) {
    fabric.Image.fromURL(imgLink, function (img: any) {
      if (img) {
        canvas.setBackgroundImage(img, canvas.renderAll.bind(canvas), {
          scaleX: canvas.width / img.width,
          scaleY: canvas.height / img.height,
        });
        setInitialText(false)
      }
    });

    setShowBox(true);
  }

  function addImageFromStorage(imgLink: any) {
    fabric.Image.fromURL(imgLink, function (img: any) {
      img.scaleX = canvas.width / img.width;
      img.scaleY = canvas.height / img.height;
      canvas.setBackgroundImage(img);
      canvas.renderAll();
    });
    setShowBox(true);
    setInitialText(false)
  }

  const changeWidth = (newWidth: any) => {
    setWidth(newWidth);
  };

  const changeHeight = (newHeight: any) => {
    setHeight(newHeight);
  };

  const changeOffsetTop = (newOffsetTop: any) => {
    setOffsetTop(newOffsetTop);
  };

  const changeOffsetLeft = (newOffsetLeft: any) => {
    setOffsetLeft(newOffsetLeft);
  };

  const changeIdentifier = (newIdentifier: any) => {
    setIdentifier(newIdentifier);
  };

  const changeOrder = (newOrder: any) => {
    setOrder(newOrder);
  };

  const changeLabel = (newLabel: React.ChangeEvent<HTMLInputElement>) => {
    setLabel(newLabel.target.value);
    let getCanvasData: any = localStorage.getItem("canvasLocalData");
    let canvasData = JSON.parse(getCanvasData);

    let getCanvasSaveData: any = localStorage.getItem("canvasSaveData");
    let canvasSaveData = JSON.parse(getCanvasSaveData);

    let getImportCanvasData: any = localStorage.getItem("importedData");
    let canvasImportData = JSON.parse(getImportCanvasData);

    let updateCanvas = canvasData.objects.find(function (e: any) {
      return e.identifier === identifier;
    });
    let updateSaveCanvas = canvasSaveData.objects.find(function (e: any) {
      return e.identifier === identifier;
    });

    if (updateCanvas) {
      updateCanvas.label = newLabel.target.value;
    }
    if (updateSaveCanvas) {
      updateSaveCanvas.label = newLabel.target.value;
    }
    localStorage.setItem("canvasLocalData", JSON.stringify(canvasData));
    localStorage.setItem("canvasSaveData", JSON.stringify(canvasSaveData));
    if (getImportCanvasData) {
      let updateCanvas = canvasImportData.objects.find(function (e: any) {
        return e.identifier === identifier;
      });
      if (updateCanvas) {
        updateCanvas.label = newLabel.target.value;
      }
      localStorage.setItem("importedData", JSON.stringify(canvasImportData));
    }
  };

  const changeInstrument = (value: any) => {
    let selectedInstrument = value;
    setInstrumentTitle(value);
    // if(selectedInstrument )

    let getInstrumentDetail: any = localStorage.getItem("instrumentDetail");
    let instrumentDetail = JSON.parse(getInstrumentDetail);

    let getCanvasData: any = localStorage.getItem("canvasLocalData");
    let canvasData = JSON.parse(getCanvasData);

    let getCanvasSaveData: any = localStorage.getItem("canvasSaveData");
    let canvasSaveData = JSON.parse(getCanvasSaveData);

    let getImportCanvasData: any = localStorage.getItem("importedData");
    let canvasImportData = JSON.parse(getImportCanvasData);

    // Filter instrument id according to chosen instrument
    const filteredInstrumentData: any = instrumentDetail.filter((intrumentArray: any) => intrumentArray.title === selectedInstrument);
    let selectedInstrumentId = filteredInstrumentData[0].id;
    let selectedMusician = filteredInstrumentData[0].instrument_musician;
    let selectedImage = filteredInstrumentData[0].musician_image;
    let selectedDescription = filteredInstrumentData[0].instrument_description;
    let selectedMusicianId = filteredInstrumentData[0].musican_id;

    setInstrumentId(selectedInstrumentId);

    if (getCanvasData) {
      let updateCanvas = canvasData.objects.find(function (e: any) {
        return e.identifier === identifier;
      });

      if (updateCanvas) {
        updateCanvas.instrument_title = selectedInstrument;
        updateCanvas.instrument_id = selectedInstrumentId;
        updateCanvas.instrument_musician = selectedMusician;
        updateCanvas.musician_image = selectedImage;
        updateCanvas.description = selectedDescription;
        updateCanvas.musician_id = selectedMusicianId;
        let newInstrumentData = canvasData.objects.map(function (e: any) {
          return e.instrument_title;
        });
        setObjectList(newInstrumentData);
        setShowBox(true);
        setRectTooltip(false);
      }
      localStorage.setItem("canvasLocalData", JSON.stringify(canvasData));
    }

    if (getCanvasSaveData) {
      let updateCanvas = canvasSaveData.objects.find(function (e: any) {
        return e.identifier === identifier;
      });

      if (updateCanvas) {
        updateCanvas.instrument_title = selectedInstrument;
        updateCanvas.instrument_id = selectedInstrumentId;
        updateCanvas.instrument_musician = selectedMusician;
        updateCanvas.musician_image = selectedImage;
        updateCanvas.description = selectedDescription;
        updateCanvas.musician_id = selectedMusicianId;
        let newInstrumentData = canvasData.objects.map(function (e: any) {
          return e.instrument_title;
        });
        setObjectList(newInstrumentData);
      }
      localStorage.setItem("canvasSaveData", JSON.stringify(canvasSaveData));
    }

    if (getImportCanvasData) {
      let updateCanvas = canvasImportData.objects.find(function (e: any) {
        return e.identifier === identifier;
      });
      if (updateCanvas) {
        updateCanvas.instrument_title = selectedInstrument;
        updateCanvas.instrument_id = selectedInstrumentId;
        updateCanvas.instrument_musician = selectedMusician;
        updateCanvas.musician_image = selectedImage;
        updateCanvas.description = selectedDescription;
        updateCanvas.musician_id = selectedMusicianId;
      }
      localStorage.setItem("importedData", JSON.stringify(canvasImportData));
      showImportObjectList();
    }
  };

  // Delete the selected object

  const removeObject = async (canvasvalues: any) => {
    const checkValid = await incognitoDetect()
    if (checkValid) {
      canvasvalues.getActiveObjects().forEach((obj: any) => {
        setWidth("0");
        setHeight("0");
        setOffsetTop("0");
        setOffsetLeft("0");
        setLabel("");
        setInstrumentTitle("");
        setInstrumentId("");
        setIdentifier("");
        let getCanvasData: any = localStorage.getItem("canvasLocalData");
        let canvasData = JSON.parse(getCanvasData);

        let getCanvasSaveData: any = localStorage.getItem("canvasSaveData");
        let canvasSaveData = JSON.parse(getCanvasSaveData);

        let getImportedData: any = localStorage.getItem("importedData");
        let canvasImportedData = JSON.parse(getImportedData);
        if (getImportedData !== null) {
          canvasImportedData.objects.forEach((objectItem: any, index: any) => {
            if (objectItem.identifier === obj.identifier) {
              canvasImportedData.objects.splice(index, 1);
              deleteSuccessConfig()
            }
          });
          localStorage.setItem(
            "importedData",
            JSON.stringify(canvasImportedData)
          );
          canvasvalues.remove(obj);
          showImportObjectList();
        } else {
          canvasData.objects.forEach((objectItem: any, index: any) => {
            if (objectItem.identifier === obj.identifier) {
              canvasData.objects.splice(index, 1);
              setOrder("");
              deleteSuccessConfig()
            }
          });
          canvasSaveData.objects.forEach((objectItem: any, index: any) => {
            if (objectItem.identifier === obj.identifier) {
              canvasSaveData.objects.splice(index, 1);
              setOrder("");
              deleteSuccessConfig()
            }
          });
          localStorage.setItem("canvasLocalData", JSON.stringify(canvasData));
          localStorage.setItem("canvasSaveData", JSON.stringify(canvasSaveData));
          canvasvalues.remove(obj);
          showObjectList();
        }
      });
    }
  };

  const deleteSuccessConfig = () => {
    let counterDec = counter - 1;
    localStorage.setItem("counterValue", JSON.stringify(counterDec));
    setDeleteSuccessAlert(true);
    setSuccessMessage("削除されました")
    setTimeout(() => {
      setDeleteSuccessAlert(false);
    }, 4000);
    setShowBox(true);
    setRectTooltip(false)
  }

  // Duplicate the selected object

  const duplicateObject = (canvasvalues: any) => {
    canvasvalues.getActiveObjects().forEach((obj: any) => {
      const duplicateRect: any = new fabric.Rect({
        height: obj.height * obj.scaleY,
        width: obj.width * obj.scaleX,
        fill: "rgba(255,0,0,0)",
        stroke: "black",
        strokeWidth: 1,
        left: obj.left,
        top: obj.top,
      });

      let getCanvasData: any = localStorage.getItem("canvasLocalData");
      let canvasData = JSON.parse(getCanvasData);

      let getCanvasSaveData: any = localStorage.getItem("canvasSaveData");
      let canvasSaveData = JSON.parse(getCanvasSaveData);

      let canvasLastObject = canvasData.objects;
      let canvasLastData = canvasLastObject[canvasLastObject.length - 1];

      let attribute1 = "identifier";
      let value1 = identifierValue;
      duplicateRect[attribute1] = value1;

      let selectedObj = canvasLastObject.find(function (e: any) {
        return e.identifier === identifier;
      });

      let attribute2 = "order";
      let counterIncr = counter + 1; //Increasing the overall counter when duplicating so that new rectangle  counter is not affected
      // let counterData = localStorage.getItem('counterValue');
      // localStorage.setItem('counterValue', JSON.stringify(value2));
      let value2 = canvasData.objects.length + 1;
      localStorage.setItem("counterValue", JSON.stringify(value2));
      duplicateRect[attribute2] = value2;
      canvasvalues.add(duplicateRect);

      const objectsData = {
        identifier: identifierValue,
        label: selectedObj.label,
        w: selectedObj.w,
        h: selectedObj.h,
        x: obj.left,
        y: obj.top,
        instrument_id: selectedObj.instrument_id,
        instrument_title: selectedObj.instrument_title,
        instrument_musician: selectedObj.instrument_musician,
        musician_image: selectedObj.musician_image,
        musician_id: selectedObj.musician_id,
        description: selectedObj.description,
        order: canvasLastData.order + 1,
        created_at: moment().format("l, h:mm:ss a"),
        recently_edited_at: moment().format("l, h:mm:ss a"),
      };
      duplicateObj.push(objectsData);

      if (getCanvasData !== null) {
        // let canvasData = JSON.parse(getCanvasData);
        canvasData.objects.push(objectsData);
        localStorage.setItem("canvasLocalData", JSON.stringify(canvasData));

        canvasSaveData.objects.push(objectsData);
        localStorage.setItem("canvasSaveData", JSON.stringify(canvasSaveData));
      } else {
        const canvasData = {
          identifier: baseIdentifierValue,
          title: "Nakuto Band Orchestra Layout",
          base_image: "http://www.goodsoundclub.com/Site_Images/layout.gif",
          objects: duplicateObj,
        };
        localStorage.setItem("canvasLocalData", JSON.stringify(canvasData));


      }
      showObjectList();

      let getImportedData: any = localStorage.getItem("importedData");

      if (getImportedData !== null) {
        let canvasImportedData = JSON.parse(getImportedData);
        canvasImportedData.objects.push(objectsData);
        localStorage.setItem(
          "importedData",
          JSON.stringify(canvasImportedData)
        );
        showImportObjectList();
      }
    });
  };

  // Json file download

  const exportData = () => {
    // let getCanvasData: any = localStorage.getItem("canvasLocalData");
    // let canvasData = JSON.parse(getCanvasData);

    let getCanvasSaveData: any = localStorage.getItem("canvasSaveData");
    let canvasSaveData = JSON.parse(getCanvasSaveData);

    let getImportedData: any = localStorage.getItem("importedData");
    let canvasImportData = JSON.parse(getImportedData);
    if (getImportedData !== null) {
      let blob = new Blob([JSON.stringify(canvasImportData)], {
        type: "application/json",
      });

      let url = URL.createObjectURL(blob);
      let link = document.createElement("a");
      link.setAttribute("href", url);
      link.setAttribute("download", "orchestra.json");

      link.click();

      URL.revokeObjectURL(url);
    } else {
      let blob = new Blob([JSON.stringify(canvasSaveData)], {
        type: "application/json",
      });

      let url = URL.createObjectURL(blob);
      let link = document.createElement("a");
      link.setAttribute("href", url);
      link.setAttribute("download", "orchestra.json");

      link.click();

      URL.revokeObjectURL(url);
    }

  };

  // To check if instrumentDetail and canvasData images are same if not REPLACE //
  const checkImageMatch = (canvasSaveData: any) => {
    fetchOrchestraIdDetail();
    // 200ms waiting time to let fetched intrument set to canvasSaveData
    setTimeout(() => {
      const getInstrumentDetail: any = localStorage.getItem("instrumentDetail");
      const instrumentDetail = JSON.parse(getInstrumentDetail)
      let getCanvasData: any = localStorage.getItem("canvasLocalData");
      let canvasData = JSON.parse(getCanvasData);
      instrumentDetail.map((instrumentData: any) => (
        canvasSaveData.objects.map((cData: any) => {
          if (instrumentData.instrument_musician === cData.instrument_musician) {
            if (instrumentData.musician_image !== cData.musician_image) {
              if (canvasData) {
                let updateCanvas = canvasData.objects.find(function (e: any) {
                  return e.identifier === cData.identifier;
                });

                if (updateCanvas) {
                  updateCanvas.instrument_title = cData.instrument_title;
                  updateCanvas.instrument_id = cData.instrument_id;
                  updateCanvas.instrument_musician = cData.instrument_musician;
                  updateCanvas.musician_image = instrumentData.musician_image;
                  updateCanvas.description = cData.description;
                  updateCanvas.musician_id = cData.musician_id;
                }
                localStorage.setItem("canvasLocalData", JSON.stringify(canvasData));
              }

              if (canvasSaveData) {
                let updateCanvas = canvasSaveData.objects.find(function (e: any) {
                  return e.identifier === cData.identifier;
                });
                if (updateCanvas) {
                  updateCanvas.instrument_title = cData.instrument_title;
                  updateCanvas.instrument_id = cData.instrument_id;
                  updateCanvas.instrument_musician = cData.instrument_musician;
                  updateCanvas.musician_image = instrumentData.musician_image;
                  updateCanvas.description = cData.description;
                  updateCanvas.musician_id = cData.musician_id;
                }
                localStorage.setItem("canvasSaveData", JSON.stringify(canvasSaveData));
              }
            }
          }
        })
      ))
    }, 200);

  }

  //Save all data
  const saveAllData = async () => {
    const checkValid = await incognitoDetect()
    function checkIfArrayIsUnique(objectListData: any) {
      return objectListData ?.length === new Set(objectListData).size;
    }
    if (checkValid) {
      const getInstrumentDetail: any = localStorage.getItem("instrumentDetail");
      const instrumentDetail = JSON.parse(getInstrumentDetail)
      const notDuplicate = checkIfArrayIsUnique(objectListData)
      if (objectListData ?.length <= instrumentDetail ?.length) {
        if (notDuplicate) {
          let getCanvasSaveData: any = localStorage.getItem("canvasSaveData");
          let canvasSaveData = JSON.parse(getCanvasSaveData);
          checkImageMatch(canvasSaveData);
          let getCanvasImportData: any = localStorage.getItem("importedData");
          let canvasImportData = JSON.parse(getCanvasImportData);
          setTimeout(async () => {
            try {
              let response;
              if (getCanvasImportData) {
                response = await axiosInstance.post('/orchestra-layout', canvasImportData);
              } else {
                response = await axiosInstance.post('/orchestra-layout', canvasSaveData);
              }
              if (response.status === 200) {
                setSaveSuccessAlert(true);
                setSuccessMessage("登録されました。");
                setTimeout(() => {
                  setSaveSuccessAlert(false);
                }, 4000);
              }
            } catch (error: any) {
              setTimeout(() => {
                window.location.reload();
              }, 1000);
            }
            // 300ms waiting time to let fetched intrument set to canvasSaveData
          }, 300);
        } else {
          setDuplicateInstrumentModal(true);
          setTimeout(() => {
            setDuplicateInstrumentModal(false);
          }, 4000);
        }
      } else {
        setRectErrorModal(true);
        setTimeout(() => {
          setRectErrorModal(false);
        }, 4000);
      }
    }
  };

  // Reset all data
  const resetData = async () => {
    const checkValid = await incognitoDetect()
    if (checkValid) {
      setWidth("0");
      setHeight("0");
      setOffsetTop("0");
      setOffsetLeft("0");
      setInstrumentId("");
      setInstrumentTitle("");
      setOrder("")
      imageLoader.current.value = null;
      setObjectList([]);
      canvas.clear();
      setShowBox(false);
      setCounter(0)
      localStorage.setItem("counterValue", JSON.stringify(counter));
      localStorage.removeItem("instrumentDetail");
      localStorage.removeItem("canvasImage");
      localStorage.removeItem("canvasDetail");
      localStorage.removeItem("canvasLocalData");
      localStorage.removeItem("canvasSaveData");
      localStorage.removeItem("baseIdentifier");
      localStorage.removeItem("counterValue");

      let response = await axiosInstance.delete(`/delete-orchesta-layout/${orchestraId}`);
      if (response.status === 200) {
        setResetSuccessAlert(true);
        setSuccessMessage("Reset successfull");
        setTimeout(() => {
          setResetSuccessAlert(false);
        }, 4000);
      }
      window.location.reload();
    }
  }

  const importError = () => {
    setImportAlert(true);
    setSuccessMessage("最初に画像を選択してください");
    setTimeout(() => {
      setImportAlert(false);
    }, 4000);
  }

  const saveError = () => {
    setImportAlert(true);
    setSuccessMessage("最初に楽器を選択してください");
    setTimeout(() => {
      setImportAlert(false);
    }, 4000);
  }

  const exportError = () => {
    setExportAlert(true);
    setSuccessMessage("レイアウトがありません");
    setTimeout(() => {
      setExportAlert(false);
    }, 4000);
  }


  return (
    <div className="App">
      <div>
        {
          saveSuccessAlert ? (
            <Alert message={successMessage} type="success" showIcon action={
              <Button size="small" type="ghost" onClick={() => setSaveSuccessAlert(false)}>
                x
              </Button>
            } />
          ) : ("")
        }
        {
          deleteSuccessAlert ? (
            <Alert message={successMessage} type="success" showIcon action={
              <Button size="small" type="ghost" onClick={() => setDeleteSuccessAlert(false)}>
                x
              </Button>
            } />
          ) : ("")
        }
        {
          resetSuccessAlert ? (
            <Alert message={successMessage} type="success" showIcon action={
              <Button size="small" type="ghost" onClick={() => setResetSuccessAlert(false)}>
                x
              </Button>
            } />
          ) : ("")
        }
        {
          duplicateInstrumentModal ? (
            <Alert message="他の楽器を選択してください。" type="warning" showIcon action={
              <Button size="small" type="ghost" onClick={() => setDuplicateInstrumentModal(false)}>
                x
              </Button>
            } />
          ) : ("")
        }
        {
          importAlert ? (
            <Alert message={successMessage} type="warning" showIcon action={
              <Button size="small" type="ghost" onClick={() => setImportAlert(false)}>
                x
              </Button>
            } />
          ) : ("")
        }
        {
          exportAlert ? (
            <Alert message={successMessage} type="warning" showIcon action={
              <Button size="small" type="ghost" onClick={() => setExportAlert(false)}>
                x
              </Button>
            } />
          ) : ("")
        }
        {
          rectErrorModal ? (
            <Alert message={`Sorry, only ${totalInstrument} rects are allowed.`} type="warning" showIcon action={
              <Button size="small" type="ghost" onClick={() => setRectErrorModal(false)}>
                x
              </Button>
            } />
          ) : ("")
        }
        {
          apiErrorAlert ? (
            <Alert message={apiErrorMessage} type="warning" showIcon action={
              <Button size="small" type="ghost" onClick={() => setApiErrorAlert(false)}>
                x
              </Button>
            } />
          ) : ("")
        }
      </div>
      <div className="mainblock">
        <Row className="mainblock-row">
          <Col xs={24} md={5} className="mainblock-col">
            <h1>ツール</h1>
            <div className={`selection-image ${showBox ? "" : "disable"}`}>
              <Tooltip title={rectTooltip ? rectTooltipMessage : ("")}>
                <span className="d-inline-block">
                  <a onClick={() => addRect(canvas)} className="box">
                    <img src={selectionTool} />
                  </a>
                </span>

              </Tooltip>
            </div>
            <div className="button-block">
              {
                showBox ? (
                  <Button disabled={showBox ? false : true} onClick={() => saveAllData()}>保存</Button>
                ) : (
                    <Button disabled={showBox ? false : true} onClick={() => saveError()}>保存</Button>
                  )
              }
            </div>
            {/* <div className="button-block">
              {
                showBox ? (
                  <Button onClick={() => exportData()}>Export</Button>
                ) : (
                  <Button onClick={() => exportError()}>Export</Button>
                )
              }

            </div>

            {
              showBox ? (
                <div className="button-block file">
                  <label>Import</label>
                  <span>
                    <input type="file" id="file" onChange={importFile} />
                  </span>

                </div>
              ) : (
                <div className="button-block">
                  <Button onClick={() => importError()}>Import</Button>
                </div>
              )
            } */}

            <div className="button-block">
              <Button disabled={showBox ? false : true} onClick={() => showResetModal()}>リセット</Button>
            </div>
            <div
              className={`invalid-file-msg ${showErrorMessage ? "display-block" : "display-none"
                }`}
            >
              <p>ファイルタイプが違います</p>
              <p>Allowed types: .json</p>
            </div>

            <div className="object_list">
              <h1>オブジェクト</h1>
              <div className="object-list">
                {objectListData ?.length > 0 && objectListData ?.map((objectData: any, index: any) => (
                  <p key={index}> {index + 1}. {objectData}</p>
                ))}
              </div>
            </div>
          </Col>
          <Col xs={24} md={10} className="mainblock-col">
            <h1>キャンバス</h1>
            <div className="canvas-wrapper">
              <canvas id="canvas" />
              {
                initialText && <p className="initial-text">開始するには画像を選択してください。</p>
              }

            </div>

            <div className="imageupload-block">
              <input
                id="uploader"
                type="file"
                ref={imageLoader}
                onChange={onhandleImage}
              />
            </div>
            <div
              className={`invalid-file-msg ${showImageErrorMessage ? "display-block" : "display-none"
                }`}
            >
              <p>ファイルタイプが違います</p>
              <p>有効なファイル: .jpeg/.jpg/.png</p>
            </div>
            <div
              className={`invalid-file-msg ${largeImageErrorMessage ? "display-block" : "display-none"
                }`}
            >
              <p>ファイルサイズが違います</p>
              <p><b>2MB</b>以下のファイルをアップロードしてください</p>
            </div>
            <div
              className={`invalid-file-msg ${imageResError ? "display-block" : "display-none"
                }`}
            >
              <p>ファイルサイズが違います</p>
              <p>16:9サイズのファイルをアップロードしてください</p>
            </div>
          </Col>
          <Col xs={24} md={7} className="mainblock-col">
            <h1>オブジェクト設定</h1>
            <div className="property-list-block">
              <div className="property-detail">
                <div className="property-name">
                  <p className="first-block">ID:</p>
                </div>
                <div className="property-value">
                  <Input
                    value={identifier}
                    onChange={changeIdentifier}
                    readOnly
                  />
                </div>
              </div>
              {/* Order Field */}
              <div className="property-detail">
                <div className="property-name">
                  <p className="first-block">表示番号:</p>
                </div>
                <div className="property-value">
                  <Input value={order} onChange={changeOrder} readOnly />
                </div>
              </div>
              <div className="property-detail">
                <div className="property-name">
                  <p>楽曲番号:</p>
                </div>
                <div className="property-value select-property-value">
                  <Input value={orchestraId} readOnly />
                </div>
              </div>

              <div className="property-detail">
                <div className="property-name">
                  <p>楽器番号:</p>
                </div>
                <div className="property-value select-property-value">
                  <Input value={instrumentId} readOnly />
                </div>
              </div>
              <div className="property-detail">
                <div className="property-name">
                  <p>楽器:</p>
                </div>
                <div className="property-value select-property-value">
                  <Select value={instrumentTitle ? instrumentTitle : "選択"} className="w-100" id="test" onChange={changeInstrument}>
                    {filteredOptions ?.map((instrument: any, index: any) => (
                      <Option key={index} value={instrument}>{instrument}</Option>
                    ))}
                  </Select>

                </div>
              </div>
              <div className="property-detail">
                <div className="property-name">
                  <p>ラベル:</p>
                </div>
                <div className="property-value label-property-value">
                  <input
                    type="text"
                    value={label}
                    onChange={changeLabel}
                    disabled={labelEnable ? false : true}
                  ></input>
                </div>
              </div>
              <div className="property-detail">
                <div className="property-name">
                  <p>X:</p>
                </div>
                <div className="property-value">
                  <Input
                    placeholder="Test"
                    type="text"
                    value={offsetLeft}
                    onChange={changeOffsetLeft}
                    readOnly
                  />
                  <span>point</span>
                </div>
              </div>
              <div className="property-detail">
                <div className="property-name">
                  <p>Y:</p>
                </div>
                <div className="property-value">
                  <Input
                    placeholder="Test"
                    type="text"
                    value={offsetTop}
                    onChange={changeOffsetTop}
                    readOnly
                  />
                  <span>point</span>
                </div>
              </div>
              <div className="property-detail">
                <div className="property-name">
                  <p>幅:</p>
                </div>
                <div className="property-value">
                  <Input
                    placeholder="Test"
                    type="text"
                    value={width}
                    onChange={changeWidth}
                    readOnly
                  />
                  <span>point</span>
                </div>
              </div>
              <div className="property-detail">
                <div className="property-name">
                  <p>高さ:</p>
                </div>
                <div className="property-value">
                  <Input
                    placeholder="Test"
                    type="text"
                    value={height}
                    onChange={changeHeight}
                    readOnly
                  />
                  <span>point</span>
                </div>
              </div>
            </div>
            <div className="actions-block">
              <h1>アクション</h1>
              <div className="actions-btn">
                <Button onClick={() => removeObject(canvas)} disabled={deleteEnable ? false : true}>削除</Button>
                {/* <Button onClick={() => duplicateObject(canvas)} disabled={showBox ? false : true}>
                  Duplicate
                </Button> */}
              </div>
            </div>
          </Col>
        </Row>
      </div>
      {/* ResetModal */}
      <ResetModal resetModal={resetModal} hideModal={closeResetModal} resetData={resetData} />
    </div>
  );
}

export default MainLayout;
