import { detectIncognito } from "detect-incognito";
import postToken from "./postToken";

// Access token from env for authorization
const access_token: any = process.env.REACT_APP_ACCESS_TOKEN;

declare global {
    interface Window {
      MSStream: string;
    }
  }


const incognitoDetect = async() => {
    let isIOS = (/iPad|iPhone|iPod/.test(navigator.platform) ||
    (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)) &&
    !window.MSStream;
    let valid;

    if(isIOS === true) {
        localStorage.setItem("accessToken", JSON.stringify(access_token))
        let validchecks = await postToken();
        return validchecks
    } else {
        valid = detectIncognito().then(async (result) => {
            if (result.isPrivate) {
                localStorage.removeItem("accessToken")
                let validchecks = await postToken();
                return validchecks
            } else {
    
                localStorage.setItem("accessToken", JSON.stringify(access_token))
                let validchecks = await postToken();
                return validchecks
    
            }
        }).catch((error) => {
            console.log(error);
            return false
        });
    }

    
    return valid
}



export default incognitoDetect;